import React from "react"
import { Global, css } from "@emotion/core"

// Theme
export const theme = {
  primary: "#E6AF2E",
  secondary: "#212738",
  black: "#000000",
  white: "#FFFFFF",
}

// Breakpoints
export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1920,
}

// Dimensions
export const dimensions = {
  contentWidth: "1170px",
}

// Global style
export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      body {
        margin: 0;
        font-size: 18px;
        font-family: Nunito, sans-serif;
        background: ${theme.black};

        @media (min-width: ${breakpoints.xl}px) {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Nunito, sans-serif;
        font-weight: 700;
        margin: 0 0 24px 0;
        padding: 0;
        line-height: 1.2;
      }
      h1 {
        font-size: 36px;

        @media (min-width: ${breakpoints.xl}px) {
          font-size: 48px;
        }
      }
      h2 {
        font-size: 24px;

        @media (min-width: ${breakpoints.xl}px) {
          font-size: 32px;
        }
      }
      h3 {
        font-size: 22px;

        @media (min-width: ${breakpoints.xl}px) {
          font-size: 24px;
        }
      }
      h4 {
        font-size: 16px;
      }
      h5 {
        font-size: 14px;
      }
      h6 {
        font-size: 12px;
      }

      p {
        margin: 0 0 24px 0;
        line-height: 1.2 @media (min-width: ${breakpoints.xl}px) {
          margin-bottom: 32px;
        }
      }

      img {
        max-width: 100%;
        margin: 0 0 24px 0;
        padding: 0;
      }

      mark {
        background-color: #ff0;
        color: #000;
      }

      button,
      input,
      optgroup,
      select,
      textarea {
        font: inherit;
        margin: 0;
        box-sizing: border-box;
      }

      a[href^="tel"] {
        color: inherit;
        text-decoration: none;
      }
    `}
  />
)
