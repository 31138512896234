import React from "react"
import PropTypes from "prop-types"

import { Input, TextArea } from "./styles"

const InputField = ({
  type,
  id,
  placeholder,
  onChange,
  valid,
  rows,
  required,
  withButton,
}) => {
  if (type === "textarea") {
    return (
      <TextArea
        valid={valid}
        id={id || "input"}
        name={id || "input"}
        placeholder={placeholder || ""}
        rows={rows || "5"}
        onChange={onChange}
        required={required || false}
      />
    )
  } else {
    return (
      <Input
        valid={valid}
        id={id || "input"}
        name={id || "input"}
        type={type || "text"}
        withButton={withButton}
        placeholder={placeholder || ""}
        onChange={onChange}
        required={required || false}
      />
    )
  }
}

InputField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  rows: PropTypes.string,
  required: PropTypes.bool,
  withButton: PropTypes.bool,
}

export default InputField
