import styled from "@emotion/styled"
import { breakpoints } from "../../../utils/styles"

export const SectionGap = styled.div`
  margin-bottom: 247px;
  @media (max-width: ${breakpoints.xl}px) {
    margin-bottom: 641px;
  }

  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 0;
  }
`

export const Section = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -10;
  background: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: space-between;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.l}px) {
    z-index: 1;
    position: relative;
    padding: 48px 24px;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: center;
  flex: 1;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 16px 0;
  }
`

export const Logo = styled.img`
  margin: 0;
`

export const ColumnTitle = styled.h3`
  margin-bottom: 8px;
`

export const ColumnText = styled.p`
  font-size: 16px;
  margin: 0;
`

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SocialLink = styled.a`
  padding: 8px;
`

export const SocialIcon = styled.img`
  margin: 0;
`

export const MailResult = styled.p`
  font-size: 14px;
  margin: 8px 0 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 12px;
  }
`

export const MailButton = styled.button`
  outline: 0;
  border: 0;
  border-radius: 0 8px 8px 0;
  color: ${({ theme }) => theme.black};
  background: ${({ theme }) => theme.primary};
  padding: 8px 16px;
  font-weight: bold;
`

export const MailForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const MailInput = styled.div`
  display: flex;
`

export const MailLabel = styled.label`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 1.2;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 22px;
  }
`
