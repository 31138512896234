import React, { Fragment, useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

import {
  Logo,
  Section,
  SectionGap,
  Column,
  ColumnTitle,
  ColumnText,
  SocialIcon,
  SocialLink,
  SocialContainer,
  MailResult,
  MailButton,
  MailForm,
  MailLabel,
  MailInput,
} from "./styles"

import InputField from "../../UI/InputField"
import HexagonIcon from "../../UI/HexagonIcon"
import ScindoLogo from "../../../assets/scindo-footer.svg"

const Footer = ({ data }) => {
  // Props
  const {
    contact_title,
    contact_text,
    subscribe_title,
    subscribe_field_text,
    social,
  } = data

  // State
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState(null)

  // Submit email to mailchimp
  const handleSubmit = async event => {
    event.preventDefault()
    const result = await addToMailchimp(email)
    setMessage(result)
  }

  // Handle change
  const handleChange = event => setEmail(event.target.value)

  // Result
  let resultMessage
  if (message && message.result) {
    resultMessage = <MailResult>{message.result.toUpperCase()}</MailResult>
  }

  return (
    <Fragment>
      <SectionGap />
      <Section>
        <Column row>
          <Logo src={ScindoLogo} alt="Scindo footer" />
        </Column>
        <Column>
          <ColumnTitle>{contact_title.text}</ColumnTitle>
          {contact_text.raw.map((item, i) => (
            <ColumnText key={`contact-text-${i}`}>{item.text}</ColumnText>
          ))}
        </Column>
        <Column>
          <MailForm onSubmit={handleSubmit}>
            <MailLabel htmlFor="newsletter">{subscribe_title.text}</MailLabel>
            <MailInput>
              <InputField
                id="newsletter"
                placeholder={subscribe_field_text}
                onChange={handleChange}
                withButton
              />
              <MailButton>Send</MailButton>
            </MailInput>
          </MailForm>
          {resultMessage}
        </Column>
        <Column row>
          <SocialContainer>
            {social.map((item, i) => (
              <SocialLink key={`social-${i}`} href={item.social_icon_link.url}>
                <HexagonIcon white>
                  <SocialIcon
                    src={item.social_icon.url}
                    alt={item.social_icon.alt}
                  />
                </HexagonIcon>
              </SocialLink>
            ))}
          </SocialContainer>
        </Column>
      </Section>
    </Fragment>
  )
}

export default Footer
