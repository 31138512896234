import React from "react"
import PropTypes from "prop-types"

import { Hexagon } from "./styles"

const HexagonIcon = ({ children, white }) => {
  return <Hexagon white={white}>{children}</Hexagon>
}

HexagonIcon.propTypes = {
  children: PropTypes.any,
  white: PropTypes.bool,
}

export default HexagonIcon
