import styled from "@emotion/styled"

export const Input = styled.input`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  border-radius: ${({ withButton }) => (withButton ? "8px 0 0 8px" : "8px")};
  outline: none;
  border: none;
`

export const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  border-radius: 8px;
  outline: none;
  border: none;
`
