import styled from "@emotion/styled"

export const Hexagon = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 52px;
  height: 30.02px;
  background: ${({ theme, white }) => (white ? theme.white : theme.black)};
  margin: 15.01px 0;

  :before,
  :after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 26px solid transparent;
    border-right: 26px solid transparent;
  }

  :before {
    bottom: 100%;
    border-bottom: 15.01px solid
      ${({ theme, white }) => (white ? theme.white : theme.black)};
  }

  :after {
    top: 100%;
    width: 0;
    border-top: 15.01px solid
      ${({ theme, white }) => (white ? theme.white : theme.black)};
  }
`
