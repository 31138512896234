import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "emotion-theming"
import { GlobalStyle, theme } from "../../utils/styles"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteLayoutQuery {
      prismicHeader {
        data {
          menu {
            menu_item_section_id
            menu_item_title
          }
        }
      }
      prismicFooter {
        data {
          contact_text {
            text
            raw {
              text
            }
          }
          contact_title {
            text
          }
          social {
            social_icon {
              alt
              url
            }
            social_icon_link {
              url
            }
          }
          subscribe_field_text
          subscribe_title {
            text
          }
        }
      }
    }
  `)

  // Data
  const header = data.prismicHeader.data
  const footer = data.prismicFooter.data

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header transparent data={header} />
      <main>{children}</main>
      <Footer data={footer} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
