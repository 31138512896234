import React, { useState, useEffect } from "react"
import ScrollIntoView from "react-scroll-into-view"
import smoothscroll from "smoothscroll-polyfill"

import {
  Section,
  Container,
  LogoLink,
  Logo,
  Hamburger,
  MenuIcon,
  Menu,
  MobileMenu,
  MenuItem,
} from "./styles"

import logo from "../../../assets/scindo-header.svg"
import logoColor from "../../../assets/scindo-header-color.svg"

import useWidth from "../../hooks/UseWidth"

const Header = ({ transparent, data }) => {
  // Hooks
  const width = useWidth()
  const mobile = Boolean(width < 992)

  // Check if client
  const isClient = typeof window !== "undefined"
  isClient && smoothscroll.polyfill()

  // State
  const [open, setOpen] = useState(false)
  const [black, setBlack] = useState(false)
  const [hide, setHide] = useState(false)

  // Menu open
  const menuOpen = open && mobile

  useEffect(() => {
    if (!isClient) return false

    let oldValue = 0
    transparent && setBlack(false)

    const scrollCheck = () => {
      // Get the new Value
      const newValue = window.pageYOffset
      transparent && setBlack(newValue >= 100)
      newValue === 0 && setHide(false)

      // Start hiding header
      if (newValue > 500) {
        // Decide scroll
        setHide(oldValue - newValue < 0)
        // Update the old value
        oldValue = newValue
      }
    }

    // Add scroll listener
    window.addEventListener("scroll", scrollCheck)
    return () => window.removeEventListener("scroll", scrollCheck)
  }, [isClient, transparent])

  // Freeze content below menu
  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : ""
  }, [menuOpen])

  // Toggle menu
  const toggleMenu = () => setOpen(!open)

  // Menu
  const menu = data.menu.map((item, i) => (
    <ScrollIntoView
      key={`menu-item-${i}`}
      selector={`#${item.menu_item_section_id}`}
    >
      <MenuItem index={i + 1} onClick={toggleMenu}>
        {item.menu_item_title}
      </MenuItem>
    </ScrollIntoView>
  ))

  return (
    <Section open={menuOpen} hide={hide} black={black}>
      <Container>
        <LogoLink to="/">
          <Logo src={menuOpen || black ? logoColor : logo} alt="Scindo" />
        </LogoLink>
        <Menu>{menu}</Menu>
        <Hamburger onClick={toggleMenu} aria-label="Menu">
          <MenuIcon open={menuOpen}>
            <span />
            <span />
            <span />
          </MenuIcon>
        </Hamburger>
      </Container>
      {open && mobile && <MobileMenu>{menu}</MobileMenu>}
    </Section>
  )
}

export default Header
