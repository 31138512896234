import styled from "@emotion/styled"
import { Link } from "gatsby"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.header`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1200;
  width: 100%;
  transition: transform 0.5s ease-in-out, background-color 0.1s ease-in-out;
  background-color: ${({ theme, open, black }) =>
    black || open ? theme.black : "transparent"};
  height: ${({ open }) => (open ? "100%;" : "initial")};
  transform: ${({ hide }) => (hide ? "translate3d(0,-100%,0)" : "none")};
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 24px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 16px 0 16px 16px;
  }
`

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  height: 48px;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    height: 32px;
  }
`

export const Menu = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const MobileMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 74px);
`

export const MenuItem = styled.div`
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  font-size: 24px;
  padding-left: 32px;
  cursor: pointer;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
    font-weight: 400;
    padding: 12px 16px;
  }
`

export const Hamburger = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0 16px;

  @media (min-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const MenuIcon = styled.div`
  width: 24px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin: 0;

  span {
    display: block;
    position: absolute;
    overflow: hidden;
    height: 3px;
    width: 100%;
    background: ${({ theme }) => theme.white};
    border-radius: 1.5px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    :nth-of-type(1) {
      transform-origin: left center;
      top: 0px;
      left: ${({ open }) => (open ? "3px" : "0")};
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0deg)")};
    }

    :nth-of-type(2) {
      top: 8px;
      transform-origin: left center;
      width: ${({ open }) => (open ? "0%" : "100%")};
      opacity: ${({ open }) => (open ? "0" : "1")};
    }

    :nth-of-type(3) {
      top: ${({ open }) => (open ? "17px" : "16px")};
      transform-origin: left center;
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0deg)")};
      left: ${({ open }) => (open ? "3px" : "0")};
    }
  }
`
